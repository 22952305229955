<template>
  <div>
    <v-row>
      <v-col md="6" cols="12">
        <v-card
        style="cursor:pointer"
        :href="$store.getters.getEventDetails[0].event_url"
        target="_blank"
        >
         <p class="font-weight-bold mb-5">
            {{$t('next_event')}}
          </p>
        <Photos
         :source="$store.getters.getEventDetails[0].logo"
         edges="rounded-circle"
         width="200px"
         height="200px"
         customStyle="text-align: center; margin: auto; margin-top: 20px; margin-bottom: 20px;"
          />

          <h2 class="headline font-weight-regular my-5">
            {{$store.getters.getEventDetails[0].event_name}}
          </h2>
           <p>
            {{$store.getters.getEventDetails[0].venue}}
          </p>
          <p>
            {{$store.getters.getEventDetails[0].address}}
          </p>
          <p>
            {{$store.getters.getEventDetails[0].date}}. {{$t('doors_open')}} {{$store.getters.getEventDetails[0].doors_open}}
          </p>
          </v-card>
      </v-col>
      <v-col md="6" cols="12">
        <LandingTicketSection />
<small>*{{$t('core_policy')}}</small>
<small>{{$t('email_receipt')}}</small>
      </v-col>

    </v-row>

<!--
      <v-card v-if="$store.getters.getYoutubeDetails.stream_link">
    <div class="videoWrapper">
    <iframe width="560" height="314" :src="$store.getters.getYoutubeDetails.stream_link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
 <p class="subheading font-weight-regular mt-4 pt-4">
         <a :href="$store.getters.getYoutubeDetails.share_link" target="_blank">{{$t('click_youtube')}} </a>
        <v-icon small right>launch</v-icon>
        </p>
        </v-card>
        <v-card outlined class="ma-2" v-else>
    <v-row align="center">
<v-col cols="6">
    <v-img width="100%" height="100%" contain :src ="$store.getters.getYoutubeDetails.event_img"></v-img>
</v-col>
<v-col cols="6">
    <v-img width="100%" height="100%" contain :src ="$store.getters.getYoutubeDetails.youtube_img"></v-img>
</v-col>
    </v-row>
        </v-card> -->
<!--
      <v-card v-if="$store.getters.getFacebookDetails.stream_link">
    <div class="videoWrapper">
   <iframe
    :src="$store.getters.getFacebookDetails.stream_link"
     width="1280" height="720"
     style="border:none;overflow:hidden"
     scrolling="no"
     frameborder="0"
     allowfullscreen="true"
     allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div>
<p class="subheading font-weight-regular mt-2">
         <a :href="$store.getters.getFacebookDetails.share_link" target="_blank">{{$t('click_facebook')}}</a>
        <v-icon small right>launch</v-icon>
        </p>
</v-card>
<v-card
 outlined
 class="ma-2"
 :href="$store.getters.getFacebookDetails.event_page_link"
 target="_blank"
 style="cursor:pointer"
 v-else>
    <v-row align="center">
<v-col cols="6">
    <v-img width="100%" height="100%" contain :src ="$store.getters.getFacebookDetails.facebook_img"></v-img>
    <small>{{$t('fb_live_notice')}}</small>
</v-col>
<v-col cols="6">
    <v-img width="100%" height="100%" contain :src ="$store.getters.getFacebookDetails.event_img"></v-img>
</v-col>
    </v-row>
        </v-card> -->

  </div>
</template>
<script>
export default {
  name: 'LandingLiveEvent',
  created () {
    this.$watch(() => {
      this.$store.dispatch('bindYouTubeCollection')
      this.$store.dispatch('bindFacebookCollection')
    })
  },
  components: {
    LandingTicketSection: () => import(/* webpackChunkName: "LandingTicketSection" */'../components/LandingTicketSection'),
    Photos: () => import(/* webpackChunkName: "Photos" */'../utils/Photos')
  }
}
</script>
<style scoped>
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  padding-top: 25px;
  height: 0;
  align-content: center;
  justify-content: center;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
<i18n>
{
    "en":{
        "watch_cabaret":"Come and join our Juerga Flamenco at Club Espagnol de Québec every month. Check out our facebook page",
        "or":"or",
        "next_event":"Our next event",
        "click_youtube":"Click here to open, comment, chat & like in Youtube",
        "click_facebook":"Click here to open, comment, chat & like in Facebook",
        "fb_live_notice":"Facebook live stream will be broadcasted in this section",
        "doors_open":"Doors open at",
        "core_policy": "One of our core policies is to protect your privacy. By default, personal information such as email and phone number are not saved.",
        "email_receipt": " Receipt will be available to save and download on the confirmation section after making the contribution. If you would like your receipt emailed, please send us a request message via the bottom right menu options."
    },
    "fr":{
        "watch_cabaret":"Venez rejoindre notre Juerga Flamenco à Club Espagnol de Québec chaque mois. Visitez notre page facebook",
        "or":"ou",
        "next_event":"Notre prochain événement",
        "click_youtube":"Cliquez ici pour ouvrir, commenter, clavarder et “aimer” sur Youtube",
        "click_facebook":"Cliquez ici pour ouvrir, commenter, clavarder et “aimer” sur Facebook",
        "fb_live_notice":"Le flux en direct Facebook sera diffusé dans cette section",
        "doors_open":"Ouverture des portes à",
        "core_policy": "L'une de nos principales politiques consiste à protéger votre vie privée. Par défaut, les informations personnelles telles que l'adresse électronique et le numéro de téléphone ne sont pas enregistrées.",
        "email_receipt": " Le reçu pourra être enregistré et téléchargé dans la section de confirmation après avoir effectué la contribution. Si vous souhaitez recevoir votre reçu par courrier électronique, veuillez nous envoyer un message de demande via les options du menu en bas à droite."
    },
    "es":{
        "watch_cabaret":"Ven y únete a nuestra Juerga Flamenco en Club Espagnol de Québec cada mes. Visite nuestra página facebook",
        "or":"o",
        "next_event":"Nuestro próximo evento",
        "click_youtube":"Haz clic aquí para abrir, comentar, chatear y hacer “like” en Youtube ",
        "click_facebook":"Haz clic aquí para abrir, comentar, chatear y hacer “like” en Facebook",
        "fb_live_notice":"La transmisión en vivo de Facebook se transmitirá en esta sección",
        "doors_open":"Las puertas se abren a",
        "core_policy": "Una de nuestras principales políticas es proteger su privacidad. Por defecto, la información personal, como el correo electrónico y el número de teléfono, no se guarda.",
        "email_receipt": " El recibo estará disponible para guardar y descargar en la sección de confirmación después de hacer la contribución. Si desea que se le envíe el recibo por correo electrónico, envíenos un mensaje de solicitud a través de las opciones del menú inferior derecho."
    }
}
</i18n>
